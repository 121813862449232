<template>
    <main class="" id="main" :class="{ 'container-slim': lgAndUp || xlAndUp }">
        <div class="container-fluid  mt-3 ">
            <div class="row  justify-content-between ">
                <div class="col-md-8 col-10 mb-3 px-0 input-bg">
                    <el-input v-model="searchInput" class="w-100  " size="large"
                        placeholder="Search Names, IDs, Payments, Events etc" :prefix-icon="Search" />
                </div>
                <div class="col-md-3 ">
                    <div class="font-weight-bold h5">Portal by</div>
                    <img src="../../assets/social/Logo.png" class="memberly-logo " alt="">
                </div>
            </div>
            <div class="row justify-content-between mt-4">
                <div class="col-sm-8 col-12 d-flex  py-4" style="background-color: #F8F7F3; border-radius: 10px;">
                    <div class="dashb-icon">
                        <img :src="churchLogo" alt="" v-if="churchLogo" class="">
                        <img v-else src="../../assets/mobileonboarding/avatar-male.png" class="" alt="" />
                    </div>
                    <div class="font-weight-bold d-flex  align-items-center h-100   ">
                        <div>
                            <div class="  ">Welcome back,</div>
                            <div>{{ tenantName }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-12 mt-sm-0 mt-4  ">
                    <div style="background: #F0F9FB;" class="col-md-12 py-4 d-flex rounded shadow">
                        <div class="d-flex mr-2 ">
                            <div class="bell-bg d-flex align-items-center justify-content-center ">
                                <el-icon :size="25" color="#ffffff">
                                    <Bell />
                                </el-icon>
                            </div>
                            <span class="notification"></span>
                        </div>
                        <div>
                            <div class="font-weight-bold">Notification</div>
                            Two(2) days to go - Paris Vacation 2023. Secure a Seat Now! Starts 23th December. Don’t Miss
                            out.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid  px-0 mt-3">
            <div class="row">
                <div class="col-md-8 mt-3">
                    <div class="col-md-12 font-weight-bold mt-4 h5">
                        Recent Financial Transcations
                    </div>
                    <div class="col-md-12 border mt-3" style="border-radius: 0.5rem;">
                        <div class="row">
                            <div class="col-md-12 mt-3 d-flex justify-content-between">
                                <div class="font-weight-bold">Recent Payments</div>
                                <div class="font-weight-bold c-pointer">Show all</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mt-3 col-md-12 table-main px-0 remove-styles2 remove-border responsiveness">
                                <table class="table table-borderless remove-styles mt-0" id="table">
                                    <thead>
                                        <tr class="small-text text-capitalize text-nowrap" style="border-bottom: 0">
                                            <th scope="col">Name</th>
                                            <th scope="col">Paid For</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody class=" font-weight-550 text-nowrap " style="font-size: 15px">
                                        <tr class="border">
                                            <td class="font-weight-550">Tope James</td>
                                            <td class="td-data">Dinner</td>
                                            <td class="td-data">200,000</td>
                                            <td class="td-data">20/11/23</td>
                                        </tr>
                                        <tr class="border">
                                            <td>Daniel Oladapo</td>
                                            <td class="td-data">Dinner</td>
                                            <td class="td-data">200,000</td>
                                            <td class="td-data">20/11/23</td>
                                        </tr>
                                        <tr class="border">
                                            <td>Joseph Oladipo</td>
                                            <td class="td-data">Dinner</td>
                                            <td class="td-data">200,000</td>
                                            <td class="td-data">20/11/23</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3 ">
                    <div class="col-md-12 font-weight-bold mt-4 h5">Events</div>
                    <div class="col-md-12 rounded border pb-3 mt-3">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Around the World in 80 Days Challenge</div>
                                    <div class="cursor-pointer">see details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid  px-0 mt-3">
            <div class="row">
                <div class="col-md-8 mt-3">
                    <div class="col-md-12 font-weight-bold mt-4 h5">
                        Resources
                    </div>
                    <div class="col-md-12 border pb-3 mt-3" style="border-radius: 0.5rem;  background: #F8F7F3;">
                        <div class="row justify-content-center">
                            <div class="col-md-11 px-0 mt-4 d-flex justify-content-between">
                                <div class="font-weight-bold">Newly Added</div>
                                <div class="font-weight-bold c-pointer">Sort by</div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 48 Laws of Power </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/play.png" alt=""> </div>
                                <div class="font-weight-bold"> Paris Vacation 2023 Highlight </div>
                            </div>
                            <div class="col-md-11 py-2 mt-3 d-flex align-items-center px-0"
                                style=" border-radius: 0.5rem; background: #E3E3E3;">
                                <div class="pl-3 mr-2"> <img src="../../assets/pdf.png" alt=""> </div>
                                <div class="font-weight-bold"> 1948 - Ignorance Freedom </div>
                            </div>
                            <div class="col-md-11 d-flex justify-content-center mt-4">
                                <div class="h5 font-weight-bold cursor-pointer">See all Resources</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3 ">
                    <div class="col-md-12 font-weight-bold mt-4 h5">Contribution & Donations</div>
                    <div class="col-md-12 rounded border pb-3 mt-3">
                        <div class="row  justify-content-center">
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">New Auditorium Building</div>
                                    <div>NGN 500.00</div>
                                    <div class="d-flex justify-content-between">
                                        <div class="text-danger font-weight-bold small mt-1">Overdue</div>
                                        <div>Pay Now</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/summer.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">Yearly Association Due</div>
                                    <div>NGN 20,000.00</div>
                                    <div class="d-flex justify-content-between">
                                        <div class="text-danger font-weight-bold small mt-1">Overdue</div>
                                        <div>Pay Now</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3 pb-1 rounded d-flex flex-wrap flex-sm-nowrap"
                                style="background: #F4F4F499;">
                                <div class="mr-3"> <img src="../../assets/global.png" alt=""> </div>
                                <div class="mt-2">
                                    <div class="font-weight-bold">New Auditorium Building</div>
                                    <div>NGN 500.00</div>
                                    <div class="d-flex justify-content-between">
                                        <div class="text-danger font-weight-bold small mt-1">Overdue</div>
                                        <div>Pay Now</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12   mt-5 explore text-white">
                        <div class="row">
                            <div class="col-md-12 "> <img class="mt-3" src="../../assets/dashboardicon/exploreimage.png"
                                    alt="">
                            </div>
                            <div class="col-md-9 ml-2 h5" style="font-weight: 550;">Memberly Ai Models Help you manage
                                your Data
                                Well,
                                Need
                                Help?</div>
                            <div class="col-md-12 mb-4 d-flex justify-content-end">
                                <el-button class="text-white h5" size="large" color="#91D10A" round>Explore
                                    now!</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-4 px-0">
            <div class="row">
                <div class="col-md-12 mt-3">
                    <img src="../../assets/manypeople.png" class="w-100" alt="">
                </div>
            </div>

        </div>
    </main>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import { Search } from '@element-plus/icons-vue'
import axios from "@/gateway/backendapi";
export default {
    setup() {
        const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
        const searchInput = ref('')
        const churchLogo = ref('')
        const tenantName = ref('')
        const tenantInfo = ref({})

        const getCurrentlySignedInUser = async () => {
            try {
                const res = await axios.get("/api/Membership/GetCurrentSignedInUser");
                tenantInfo.value = res.data;
                // const lastCharacters = tenantInfo.value.churchName
                // const lastCharacters = tenantInfo.value.churchName.slice(-5);
                // tenantName.value = tenantInfo.value.churchName.replace(lastCharacters, "...");
                tenantName.value = tenantInfo.value.churchName;
                getChurchProfile()
            } catch (err) {
                console.log(err);
            }
        };

        getCurrentlySignedInUser();

        const getChurchProfile = async () => {
            try {
                let res = await axios.get(`/GetChurchProfileById?tenantId=${tenantInfo.value.tenantId}`)
                churchLogo.value = res.data.returnObject.logo
            }
            catch (err) {
                console.log(err)
            }
        }

        return {
            mdAndUp, lgAndUp, xlAndUp,
            Search,
            searchInput,
            tenantInfo,
            churchLogo,
            tenantName
        }

    },
}
</script>

<style scoped>
.memberly-logo {
    width: 150px;
}

.dashb-icon img {
    width: 90px;
}

.explore {
    background: linear-gradient(180deg, #58C492 0%, #4E876D 100%);
    border-radius: 0.5rem;

}

.remove-border {
    box-shadow: none !important;
}

.responsiveness {
    max-width: 100%;
    /* overflow-y: scroll; */
}

.table {
    width: 100% !important;
    box-shadow: none !important;
    border: none !important;
    text-align: left;
    margin-bottom: auto !important;
    padding-bottom: 0.5rem;
}

.remove-styles2 {
    padding-right: 0;
    padding-left: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    overflow-x: scroll;
}

.remove-styles {
    border: none !important;
    border-top: none !important;
    box-shadow: none !important;
    border-bottom: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.td-data {
    color: #707683;
    font-size: 13px !important;
    font-weight: 500;

}

.table thead th {
    font-weight: 550;
    opacity: 50% !important;
}

.table thead {
    border: none;
}

/* .table thead tr {
  border: none;
} */
table {
    border: none !important;
    border-spacing: 0px;
}

.table-header-area-main {
    /* background-color: #ebeff4; */
    color: #334D6E !important;
    font-weight: 500 !important;
    font-size: 13px !important;

}

.table-main {
    width: 100% !important;
    text-align: left !important;
    margin-bottom: auto !important;
    padding-bottom: 0.5rem !important;
}

.notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.bell-bg {
    background: #3C7E58;
    border-radius: 50%;
    height: 35px;
    width: 35px;
}
</style>