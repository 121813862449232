<template>
  <main class="" id="main" :class="{ 'container-slim': lgAndUp || xlAndUp }">
    <div class="container-fluid  mt-3 ">
      <div class="row  justify-content-between ">
        <div class="col-md-8 col-10 mb-3 px-0 input-bg">
          <el-input v-model="searchInput" class="w-100  " size="large"
            placeholder="Search Names, IDs, Payments, Events etc" :prefix-icon="Search" />
        </div>
        <div class="col-md-1 col-2 d-flex h-100 mt-2 ">
          <div class="d-flex align-items-center">
            <!-- <div class="d-flex align-items-center" @click="showNotification"> -->
            <el-icon :size="30">
              <Bell />
            </el-icon>
          </div>
          <span class="notification"></span>
        </div>
        <div class="col-md-2 col-12 border  bg-white rounded d-flex  align-items-center h-100 ">
          <div class="row flex-nowrap  justify-content-between mt-2">
            <div class="dashb-icon  col-md-9 mx-0 px-0  ">
              <div class="row flex-nowrap justify-content-between    ">
                <div class="col-md-3">
                  <img :src="churchLogo" alt="" v-if="churchLogo" class="">
                  <img v-else src="../../assets/mobileonboarding/avatar-male.png" class="" alt="" />
                </div>
                <div class="col-md-9 font-weight-bold overflow-auto  " style="height: 2.5rem;">
                  <div class="small  ">Welcome back</div>
                  <div>{{ tenantName }}</div>
                </div>


              </div>
            </div>
            <!-- <div class=" px-0   "> -->
            <div class=" col-md-3 ">
              <el-dropdown class=" w-100   ">
                <div class="col-md-2 px-0 mt-1  "><el-icon :size="25" class="el-icon--right "><arrow-down /></el-icon>
                </div>

                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>Account Settings</el-dropdown-item>
                  </el-dropdown-menu>
                </template>

              </el-dropdown>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-md-12 h5 mt-4 font-weight-bold">Quick Actions</div>
        <div class="col-md-3 col-12 d-flex flex-column mb-3 can-do-links ">
          <!-- <div class="can-do-links can-do"> -->
          <router-link to="/tenant/people/add" class="text-decoration-none">Add Member</router-link>
          <router-link to="/tenant/sms/compose" class="text-decoration-none">Send SMS</router-link>
          <router-link to="/tenant/pledge/makepledge" class="text-decoration-none">New Contribution</router-link>
          <router-link to="/tenant/pledge/pledgeslist" class="text-decoration-none">Record Payments</router-link>
          <!-- </div> -->

          <div class="col-md-12 px-0 mt-5 col-12">
            <div class="col-md-12  mt-5 explore text-white">
              <div class="row">
                <div class="col-md-12 "> <img class="mt-3" src="../../assets/dashboardicon/exploreimage.png" alt="">
                </div>
                <div class="col-md-9 ml-2 h5" style="font-weight: 550;">Memberly Ai Models Help you manage your Data
                  Well,
                  Need
                  Help?</div>
                <div class="col-md-12 mb-4 d-flex justify-content-end">
                  <el-button class="text-white h5" size="large" color="#91D10A" round>Explore now!</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4 col-sm-6 col-12  ">
              <div class="row">
                <div class=" col-md-11" style="height: 8rem; background-color: #F8F7F3; border-radius: 8px;">
                  <div class="row">
                    <div class="col-md-12 mt-2">
                      <img src="../../assets/dashboardicon/Users.png" alt="">
                    </div>
                    <div class="col-md-12 font-weight-bold">Memebership Size</div>
                    <div class="col-md-12 text-right mt-4 h5  font-weight-bold">33</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12  mt-3 mt-sm-0 ">
              <div class="row">
                <div class=" col-md-11   " style="height: 8rem; background-color: #F0F9FB; border-radius: 8px;">
                  <div class="row">
                    <div class="col-md-12 mt-2">
                      <img src="../../assets/dashboardicon/ArrowSquareDown.png" alt="">
                    </div>
                    <div class="col-md-12 font-weight-bold">Income</div>
                    <div class="col-md-12 text-right mt-4 h5  font-weight-bold">NGN 3,975,000</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-4 col-sm-6 col-12 mt-3 mt-md-0 ">
              <div class="row">
                <div class=" col-md-11  " style="height: 8rem; background-color: #DFECE6; border-radius: 8px;">
                  <div class="row">
                    <div class="col-md-12 mt-2">
                      <img src="../../assets/dashboardicon/ArrowSquareUp.png" alt="">
                    </div>
                    <div class="col-md-12 font-weight-bold">Expenses</div>
                    <div class="col-md-12 text-right mt-4 h5  font-weight-bold">NGN 695,000</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-12 mt-3 d-flex justify-content-end">
              <div class=" d-flex">
                <el-checkbox v-model="checked1" size="large" />
                <span class="mt-2 ml-1">Hide Money Figures</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-12 col-12">
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col-md-12  border" style="border-radius: 0.5rem;">
                    <div class="h3 mt-3" style="font-weight: 550;">Current Year Financial</div>
                    <div class="w-100">
                      <PaymentColumnChart2 domId="chart1" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 border mt-3" style="border-radius: 0.5rem;">
                <div class="row">
                  <div class="col-md-12 mt-3 d-flex justify-content-between">
                    <div class="font-weight-bold">Recent Payments</div>
                    <div class="font-weight-bold c-pointer">Show all</div>
                  </div>
                </div>
                <div class="row">
                  <div class="mt-3 col-md-12 table-main px-0 remove-styles2 remove-border responsiveness">
                    <table class="table table-borderless remove-styles mt-0" id="table">
                      <thead>
                        <tr class="small-text text-capitalize text-nowrap" style="border-bottom: 0">
                          <th scope="col">Name</th>
                          <th scope="col">Paid For</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody class=" font-weight-550 text-nowrap " style="font-size: 15px">
                        <tr class="border">
                          <td class="font-weight-550">Tope James</td>
                          <td class="td-data">Dinner</td>
                          <td class="td-data">200,000</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                        <tr class="border">
                          <td>Daniel Oladapo</td>
                          <td class="td-data">Dinner</td>
                          <td class="td-data">200,000</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                        <tr class="border">
                          <td>Joseph Oladipo</td>
                          <td class="td-data">Dinner</td>
                          <td class="td-data">200,000</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4 border" style="border-radius: 0.5rem;">
                <div class="row">
                  <div class="col-md-12 mt-3 d-flex justify-content-between">
                    <div class="font-weight-bold">Recent Expenses</div>
                    <div class="font-weight-bold c-pointer">Show all</div>
                  </div>
                </div>
                <div class="row">
                  <div class="mt-3 col-md-12 table-main px-0 remove-styles2 remove-border responsiveness">
                    <table class="table table-borderless remove-styles mt-0" id="table">
                      <thead>
                        <tr class="small-text text-capitalize text-nowrap" style="border-bottom: 0">
                          <th scope="col">Name</th>
                          <th scope="col">Profession</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody class=" font-weight-550 text-nowrap " style="font-size: 15px">
                        <tr class="border">
                          <td class="font-weight-550">Tope James</td>
                          <td class="td-data">Software</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                        <tr class="border">
                          <td>Daniel Oladapo</td>
                          <td class="td-data">Software</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                        <tr class="border">
                          <td>Joseph Oladipo</td>
                          <td class="td-data">Software</td>
                          <td class="td-data">20/11/23</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 px-0 mt-4 d-flex flex-wrap">
              <div class="col-md-6 col-12">
                <div class="col-md-12   border " style=" border-radius: 0.5rem;">
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-between h3 mt-4" style="font-weight: 550;">
                      <div>

                        Expense Chart </div>
                      <div>
                        <div class="row">
                          <div class="  col-md-12 d-flex mt-2 justify-content-end ">
                            <div>
                              <el-dropdown trigger="click" class="w-100">
                                <span class="el-dropdown-link w-100">
                                  <div class="d-flex justify-content-between h6 text-secondary w-100" size="large">
                                    <span>{{
                                      selectedItem && selectedItem.name
                                      ? selectedItem.name
                                      : selectedItem
                                    }}</span>
                                    <div>
                                      <el-icon class="el-icon--right">
                                        <arrow-down />
                                      </el-icon>
                                    </div>
                                  </div>
                                </span>
                                <template #dropdown>
                                  <el-dropdown-menu>
                                    <el-dropdown-item v-for="(itm, indx) in expenseMontWeekly" :key="indx"
                                      @click="selectItem(itm)">{{
                                        itm.name }}
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </template>
                              </el-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <ExpensePieChart domId="chart3" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 col-12 mt-3 mt-sm-0 ">
            <div class="col-md-12 h3  border" style="font-weight: 550; border-radius: 0.5rem;">
              <div class="row">
                <div class="col-md-12 mt-4">
                Payment chart
              </div>

              <div class="col-md-12">
                <PaymentColumnChart domId="chart2" />
              </div>
              </div>
            </div>
          </div> -->
              <div class="col-md-6 col-12 mt-3 mt-sm-0 ">
                <div class="col-md-12 h-100 border" style="border-radius: 0.5rem;">
                  <div class="row my-3">
                    <div class="col-md-12 d-flex justify-content-between">
                      <div class="h3" style="font-weight: 550;">Monthly inflow</div>
                      <div>
                        <div class="row">
                          <div class="  col-md-12 d-flex mt-2 justify-content-end ">
                            <div>
                              <el-dropdown trigger="click" class="w-100">
                                <span class="el-dropdown-link w-100">
                                  <div class="d-flex justify-content-between h6 text-secondary w-100" size="large">
                                    <span>{{
                                      selectedMonth && selectedMonth.name
                                      ? selectedMonth.name
                                      : selectedMonth
                                    }}</span>
                                    <div>
                                      <el-icon class="el-icon--right">
                                        <arrow-down />
                                      </el-icon>
                                    </div>
                                  </div>
                                </span>
                                <template #dropdown>
                                  <el-dropdown-menu>
                                    <el-dropdown-item v-for="(itm, indx) in monthlyInflowItems" :key="indx"
                                      @click="selectMonth(itm)">{{
                                        itm.name }}
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </template>
                              </el-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <apexchart class="w-100 " type="area" :options="areaOptions" :series="seriesarea">
                      </apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-12 d-flex flex-wrap mt-3 mt-sm-0 ">
          <div class="col-md-6 mt-4 border" style="border-radius: 0.5rem;">
            <div class="row">
              <div class="col-md-12 mt-3 d-flex justify-content-between">
                <div>Newly Added Members</div>
                <div class="font-weight-bold c-pointer">Show all</div>
              </div>
            </div>
            <div class="row">
              <div class="mt-3 col-md-12 table-main px-0 remove-styles2 remove-border responsiveness">
                <table class="table table-borderless remove-styles mt-0" id="table">
                  <thead>
                    <tr class="small-text text-capitalize text-nowrap" style="border-bottom: 0">
                      <th scope="col">Name</th>
                      <th scope="col">Profession</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody class=" font-weight-550 text-nowrap " style="font-size: 15px">
                    <tr class="border">
                      <td class="font-weight-550">Tope James</td>
                      <td class="td-data">Software</td>
                      <td class="td-data">20/11/23</td>
                    </tr>
                    <tr class="border">
                      <td>Daniel Oladapo</td>
                      <td class="td-data">Software</td>
                      <td class="td-data">20/11/23</td>
                    </tr>
                    <tr class="border">
                      <td>Joseph Oladipo</td>
                      <td class="td-data">Software</td>
                      <td class="td-data">20/11/23</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div> -->
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="col-md-12 rounded-lg mt-4 border" style="border-radius: 0.5rem;">
                <!-- Fiscal year/ -->
                <div class="row mt-4">
                  <div class="col-md-7 font-weight-bold">Fiscal year</div>
                  <div class="col-md-5 d-flex justify-content-between">
                    <div>
                      <div class="row">
                        <div class=" col-md-12 d-flex justify-content-end ">
                          <div>
                            <el-dropdown trigger="click" class="w-100">
                              <span class="el-dropdown-link w-100">
                                <div class="d-flex justify-content-between h6 text-secondary w-100" size="large">
                                  <span>{{
                                    selectedPreviousYear && selectedPreviousYear.name
                                    ? selectedPreviousYear.name
                                    : selectedPreviousYear
                                  }}</span>
                                  <div>
                                    <el-icon class="el-icon--right">
                                      <arrow-down />
                                    </el-icon>
                                  </div>
                                </div>
                              </span>
                              <template #dropdown>
                                <el-dropdown-menu>
                                  <el-dropdown-item v-for="(itm, indx) in previousFiscalYear" :key="indx"
                                    @click="selectedPrevYear(itm)">{{
                                      itm.name }}
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                            </el-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <div class="  col-md-12 d-flex justify-content-end ">
                          <div>
                            <el-dropdown trigger="click" class="w-100">
                              <span class="el-dropdown-link w-100">
                                <div class="d-flex justify-content-between h6 text-secondary w-100" size="large">
                                  <span>{{
                                    selectedCurrentYear && selectedCurrentYear.name
                                    ? selectedCurrentYear.name
                                    : selectedCurrentYear
                                  }}</span>
                                  <div>
                                    <el-icon class="el-icon--right">
                                      <arrow-down />
                                    </el-icon>
                                  </div>
                                </div>
                              </span>
                              <template #dropdown>
                                <el-dropdown-menu>
                                  <el-dropdown-item v-for="(itm, indx) in currentFiscalYear" :key="indx"
                                    @click="selectedCurrYear(itm)">{{
                                      itm.name }}
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                            </el-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-top py-3">
                  <div class="col-md-6 col-12">
                    Income
                  </div>
                  <div class="col-md-6 col-12 ">
                    <div class="row flex-nowrap justify-content-between">
                      <div class=" text-md-right text-left col-md-6 font-weight-bold">
                        225k
                      </div>
                      <div class=" col-md-6 text-right font-weight-bold">
                        325k
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-top py-3">
                  <div class="col-md-6 col-12">
                    Expense
                  </div>
                  <div class="col-md-6 col-12 ">
                    <div class="row flex-nowrap justify-content-between">
                      <div class=" text-md-right text-left col-md-6 font-weight-bold">
                        225k
                      </div>
                      <div class=" col-md-6 text-right font-weight-bold">
                        325k
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-top py-3">
                  <div class="col-md-6 col-12">
                    Net Balance
                  </div>
                  <div class="col-md-6 col-12 ">
                    <div class="row flex-nowrap justify-content-between">
                      <div class=" text-md-right text-left col-md-6 font-weight-bold">
                        225k
                      </div>
                      <div class=" col-md-6 text-right font-weight-bold">
                        325k
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid px-0 mt-4">


      <div class="container-fluid mt-4 px-0">

      </div>
    </div>
  </main>
</template>

<script>
import PaymentColumnChart from "@/components/charts/memberlyColumnChart.vue";
import PaymentColumnChart2 from "@/components/charts/memberlyColumChart2.vue";
import ExpensePieChart from "@/components/charts/memberlyPieChart.vue"
import { computed, onMounted, ref, watchEffect } from "vue";
import { Search } from '@element-plus/icons-vue'
// import { useRoute } from 'vue-router';
// import mixin from "@/mixins/expiredSub.mixin.js"
// import router from "@/router/index";
import axios from "@/gateway/backendapi";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import Tooltip from "primevue/tooltip";
import store from "../../store/store";






export default {
  // mixins: [mixin],
  components: {
    Search,
    PaymentColumnChart,
    PaymentColumnChart2,
    ExpensePieChart
    // UserDetails,
    // NewColumnChart,
    // NewPieChart,
    // Latest,
    // Celebrant,

  },


  directives: {
    tooltip: Tooltip,
  },

  setup() {

    const tenantName = ref('')
    const searchInput = ref('')
    const checked1 = ref(false)
    const tenantInfo = ref({})
    const churchLogo = ref('');
    const selectedPreviousYear = ref('Previous');
    const selectedCurrentYear = ref('Current');
    const selectedItem = ref('Monthly');
    const selectedMonth = ref('January');
    const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint()

    const previousFiscalYear = ref([
      // { name: "Branches", id: 1 },
      { name: "2007", id: 1 },
      { name: "2008", id: 2 },
      { name: "2009", id: 3 },
      { name: "2010", id: 4 },
    ]);
    const currentFiscalYear = ref([
      // { name: "Branches", id: 1 },
      { name: "2020", id: 1 },
      { name: "2021", id: 2 },
      { name: "2022", id: 3 },
      { name: "2023", id: 4 },
    ]);
    const expenseMontWeekly = ref([
      { name: "Monthly", id: 1 },
      { name: "Weekly", id: 2 },
    ]);
    const monthlyInflowItems = ref([
      { name: "January", id: 1 },
      { name: "February", id: 2 },
      { name: "March", id: 3 },
      { name: "April", id: 4 },
      { name: "May", id: 5 },
      { name: "June", id: 6 },
      { name: "July", id: 7 },
      { name: "August", id: 8 },
      { name: "September", id: 9 },
      { name: "October", id: 10 },
      { name: "November", id: 11 },
      { name: "December", id: 12 },
    ]);

    const selectMonth = (item) => {
      selectedMonth.value = item
    }
    const selectedPrevYear = (item) => {
      selectedPreviousYear.value = item
    }
    const selectedCurrYear = (item) => {
      selectedCurrentYear.value = item
    }
    const selectItem = (item) => {
      selectedItem.value = item
    }

    const areaOptions = ref({
      chart: {
        height: 280,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },

      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: ['1', '2', '3', '4', '5', '6', '7', '8'],
      },
    });

    const seriesarea = ref([{
      data: [20, 78, 65, 33, 38, 11, 48, 90]
    }]);

    const getCurrentlySignedInUser = async () => {
      try {
        const res = await axios.get("/api/Membership/GetCurrentSignedInUser");
        tenantInfo.value = res.data;
        const lastCharacters = tenantInfo.value.churchName.slice(-5);
        tenantName.value = tenantInfo.value.churchName.replace(lastCharacters, "...");
        getChurchProfile()
      } catch (err) {
        console.log(err);
      }
    };

    getCurrentlySignedInUser();

    const getChurchProfile = async () => {
      try {
        let res = await axios.get(`/GetChurchProfileById?tenantId=${tenantInfo.value.tenantId}`)
        churchLogo.value = res.data.returnObject.logo
      }
      catch (err) {
        console.log(err)
      }
    }


    return {
      selectedPrevYear,
      selectedCurrYear,
      selectItem,
      selectMonth,
      selectedMonth,
      selectedItem,
      expenseMontWeekly,
      monthlyInflowItems,
      tenantName,
      selectedPreviousYear,
      selectedCurrentYear,
      previousFiscalYear,
      currentFiscalYear,
      tenantInfo,
      checked1,
      churchLogo,
      searchInput,
      Search,
      seriesarea,
      areaOptions,
      mdAndUp, lgAndUp, xlAndUp
    }
  }
};
</script>

<style scoped>
.dashb-icon img {
  width: 30px;
}

.adjust-view {
  position: relative;
  top: 35px;
  right: 10px;
  z-index: 1;
}

.explore {
  background: linear-gradient(180deg, #58C492 0%, #4E876D 100%);
  border-radius: 0.5rem;

}

.remove-border {
  box-shadow: none !important;
}

.responsiveness {
  max-width: 100%;
  /* overflow-y: scroll; */
}

.table {
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
  text-align: left;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem;
}

.remove-styles2 {
  padding-right: 0;
  padding-left: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  overflow-x: scroll;
}

.remove-styles {
  border: none !important;
  border-top: none !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.td-data {
  color: #707683;
  font-size: 13px !important;
  font-weight: 500;

}

.table thead th {
  font-weight: 550;
  opacity: 50% !important;
}

.table thead {
  border: none;
}

/* .table thead tr {
  border: none;
} */
table {
  border: none !important;
  border-spacing: 0px;
}

.table-header-area-main {
  /* background-color: #ebeff4; */
  color: #334D6E !important;
  font-weight: 500 !important;
  font-size: 13px !important;

}

.table-main {
  width: 100% !important;
  text-align: left !important;
  margin-bottom: auto !important;
  padding-bottom: 0.5rem !important;
}

.can-do-links a {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #323C47;

}


.notification {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}
</style>